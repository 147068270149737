var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-table-large"),
              ]),
              _c(
                "span",
                {
                  staticClass: "secondary--text font-weight-bold text-truncate",
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$store.state.snapshotStore.selectedTableForPreview
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("delete-button", {
                    attrs: {
                      objectType: "column",
                      icon: false,
                      id: _vm.selected[0]?.tid,
                      disabled: _vm.selected.length === 0,
                      buttonName: "Delete",
                      block: false,
                      apiURL: `/instances/${_vm.$route.params.iid}/columns/delete`,
                      protocol: "POST",
                      selected: _vm.selected,
                      isAsyncDeletion: true,
                      showDialog: true,
                      objectName: "Columns",
                      warningText: `Are you sure you want to delete the selected columns permanently?`,
                    },
                    on: {
                      error: function ($event) {
                        return _vm.errorMessage($event.error)
                      },
                      deleting: function ($event) {
                        return _vm.emptySelected(!$event.value)
                      },
                    },
                  }),
                  _c("v-spacer"),
                  _c("v-text-field", {
                    attrs: {
                      "prepend-inner-icon": "mdi-filter",
                      autofocus: "",
                      "hide-details": "",
                      solo: "",
                      flat: "",
                      "background-color": "grey lighten-4",
                      dense: "",
                      label: "Filter columns...",
                      clearable: "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "text-truncate",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.columnsFetching ? [] : _vm.currentColumns,
                  search: _vm.search,
                  loading: _vm.columnsFetching,
                  "item-key": "short_id",
                  "items-per-page": 10,
                  "sort-desc": [true, true],
                  "show-select": "",
                  options: _vm.tableOptions,
                  "footer-props": {
                    "items-per-page-options": _vm.itemsPerPageOptions,
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function ({ pagination, options, updateOptions }) {
                        return [
                          _c("v-data-footer", {
                            staticClass: "no-border",
                            attrs: {
                              pagination: pagination,
                              options: options,
                              itemsPerPageOptions: _vm.itemsPerPageOptions,
                              "items-per-page-text":
                                "$vuetify.dataTable.itemsPerPageText",
                            },
                            on: { "update:options": updateOptions },
                          }),
                        ]
                      },
                    },
                    {
                      key: `item.short_id`,
                      fn: function ({ item }) {
                        return [
                          _c("div", { staticClass: "d-flex align-center" }, [
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v(_vm._s(item.short_id)),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: `item.coltype`,
                      fn: function ({ item }) {
                        return [_vm._v(" " + _vm._s(item.coltype) + " ")]
                      },
                    },
                    {
                      key: `item.description`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { "max-width": "500", bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            { staticClass: "text-truncate" },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.description
                                                    ? item.description
                                                    : "no description available"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    item.description
                                      ? item.description
                                      : "no description available"
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.actions`,
                      fn: function ({ item }) {
                        return [
                          _c("copy-to-clipboard", {
                            attrs: {
                              textToCopy: _vm.quotedName(item.short_id),
                              iconButton: "",
                              type: "button",
                              tooltip: "Copy name to clipboard",
                            },
                          }),
                          _vm.isDevelopment
                            ? _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    left: "",
                                    "offset-x": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "mr-3",
                                                  attrs: {
                                                    text: "",
                                                    color: "secondary",
                                                    small: "",
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("more_horiz"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    {
                                      staticClass: "py-4",
                                      attrs: { nav: "", dense: "" },
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c("rename-button", {
                                                attrs: {
                                                  selected: [item],
                                                  objectType: "column",
                                                  dispatchFunction: () => {
                                                    _vm.$store.dispatch(
                                                      "snapshotStore/fetchColumnAndPreviewData",
                                                      {
                                                        tid: item.tid,
                                                        tableName:
                                                          item.short_id,
                                                      }
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c("edit-description-button", {
                                                attrs: {
                                                  selected: [item],
                                                  objectType: "column",
                                                  dispatchFunction: () => {
                                                    _vm.$store.dispatch(
                                                      "snapshotStore/fetchColumnAndPreviewData",
                                                      {
                                                        tid: item.tid,
                                                        tableName:
                                                          item.short_id,
                                                      }
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider", { staticClass: "my-3" }),
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c("delete-button", {
                                                attrs: {
                                                  objectType: "column",
                                                  icon: false,
                                                  buttonName: "Delete",
                                                  apiURL: `/instances/${_vm.$route.params.iid}/columns/delete`,
                                                  protocol: "POST",
                                                  selected: [item],
                                                  isAsyncDeletion: true,
                                                  showDialog: true,
                                                  objectName: "Columns",
                                                  warningText: `Are you sure you want to delete the selected columns permanently?`,
                                                },
                                                on: {
                                                  error: function ($event) {
                                                    return _vm.errorMessage(
                                                      $event.error
                                                    )
                                                  },
                                                  deleting: function ($event) {
                                                    return _vm.emptySelected(
                                                      !$event.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "no-results",
                      fn: function () {
                        return [
                          _c(
                            "v-alert",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                value: true,
                                color: "error",
                                icon: "warning",
                                outlined: "",
                              },
                            },
                            [
                              _vm._v(
                                'Your search for "' +
                                  _vm._s(_vm.search) +
                                  '" found no results.'
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }